.document-wrapper {
  display: flex;
  flex-direction: column;

  &.document-wrapper-table {
    height: 100%;

    > .document-content {
      height: 100%;
    }
  }

  > .document-content {
    display: flex;
    flex-direction: column;

    > .document {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 816px;
      min-height: 1056px;
      background-color: white;
      border: solid var(--rs-list-border) 1px;
      padding: 30px;
      transform: scale(1);
      transform-origin: top;
    }
  }
}
