#section-element-option-update-form {
  .table-column-index {
    min-width: 30px;
    justify-content: center;
  }

  .table-column-activity {
    flex: 1;
    min-width: 100px;
  }

  .table-column-subcontractor-amount {
    width: 175px;
  }

  .table-column-contractor-amount {
    width: 175px;
  }

  .table-column-promoter-amount {
    width: 175px;
  }
}
