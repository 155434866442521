@use "styles/variables.scss" as *;

#project-reports-view {
  .table-column-index {
    min-width: 55px;
    justify-content: center;

    .report-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-name {
    flex: 2;
    min-width: 200px;
  }
}
