#form-report-link-form {
  .table-column-index {
    min-width: 55px;
    justify-content: center;

    .form-section-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }

    .section-element-index {
      display: flex;
      background-color: #e7e7ef;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-label {
    flex: 1;
    min-width: 200px;
  }

  .table-column-is-valid {
    width: 50px;
  }

  .table-column-type {
    flex: 1;
    min-width: 150px;
  }

  .table-column-input {
    flex: 1;
    min-width: 200px;
  }
}
