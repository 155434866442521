@use "styles/variables.scss" as *;

.layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  &.layout-has-sidebar {
    > aside {
      display: flex;
    }

    > section {
      width: calc(100% - $sidebar-width);
    }
  }

  > section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    > nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: $navbar-height;
      width: 100%;
      background-color: #e7e7ef;
      border-bottom: 1px solid var(--rs-navs-tab-border);
      padding: 0 8px 0 16px;
      gap: 10px;

      > .btn-bars {
        display: none;
      }

      > .right {
        margin-left: auto;
        display: flex;

        > .nav {
          display: flex;
          border-right: $primary-color 1px solid;
          padding-right: 8px;
          gap: 2px;
        }

        > .logout {
          &.logout-with-left-border {
            border-left: $primary-color 1px solid;
            padding-left: 8px;
          }
        }
      }
    }

    > section {
      display: flex;
      flex-direction: column;
      height: calc(100% - $navbar-height);
      width: 100%;
    }
  }

  &.layout-is-fullscreen {
    > section {
      > section {
        height: 100%;
      }
    }
  }
}
