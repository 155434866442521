#contract-selection-confirm-form {
  width: 816px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  > .rs-message {
    background-color: #fff5e4 !important;
    text-transform: uppercase;

    > .rs-message-container {
      padding: 15px;

      .rs-checkbox {
        padding-right: 10px;
      }
    }
  }
}
