@use "styles/variables.scss" as *;

#project-report-view {
  .table-column-index {
    min-width: 55px;
    justify-content: center;

    .report-room-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }

    .report-room-element-index {
      display: flex;
      background-color: #e7e7ef;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-name {
    flex: 1;
    min-width: 100px;
  }

  .table-column-display {
    flex: 1;
    min-width: 100px;
  }
}
