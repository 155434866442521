#units-form {
  .table-column-number {
    width: 120px;
  }

  .table-column-bedroom-count {
    flex: 1;
    min-width: 200px;
  }

  .table-column-bathroom-count {
    flex: 1;
    min-width: 200px;
  }

  .table-column-half-bathroom-count {
    flex: 1;
    min-width: 200px;
  }

  .table-column-form {
    flex: 3;
    min-width: 600px;
  }
}
