#project-units-view {
  .table-column-floor {
    > div {
      display: flex;
      gap: 4px;
    }
  }

  .table-column-number {
    width: 120px;
  }

  .table-column-rooms {
    width: 50px;
  }

  .table-column-state {
    width: 200px;
  }

  .table-column-customers {
    flex: 1;
    min-width: 200px;

    .contract-customer-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .table-column-selection {
    flex: 1;
    min-width: 200px;
  }

  .table-column-form {
    flex: 1;
    min-width: 200px;
  }

  .table-column-total {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-paid {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-refunded {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-balance {
    width: 155px;
    justify-content: flex-end;
  }
}
